import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavExtrasService {

    extras: any;
    canGetExtras = false;

    constructor() { }

    public setExtras(data){
      this.extras = data;
      this.canGetExtras = true;
      console.log(this.canGetExtras + " - can get set");
    }

    public getExtras(){
      console.log(this.canGetExtras + " - can get");

      if (this.canGetExtras){
        this.canGetExtras = false;
        return this.extras;
      }
      else{
        return null;
      }
    }
}